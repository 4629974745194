@use 'sass:map';

$_blue: (
  '50': #d6f5ff,
  '100': #b3edff,
  '200': #61daff,
  '300': #14c8ff,
  '400': #0098c7,
  '500': #005c77,
  '600': #004a61,
  '700': #003747,
  '800': #00232e,
  '900': #001419,
);

$_green: (
  '50': #eafbf1,
  '100': #d4f7e2,
  '200': #aaeec5,
  '300': #7fe6a8,
  '400': #55dd8b,
  '500': #2dd570,
  '600': #22aa58,
  '700': #198042,
  '800': #11552c,
  '900': #082b16,
);

$_grayscale: (
  '50': #f1f1f4,
  '100': #e0e1e6,
  '200': #c4c5cf,
  '300': #a5a7b6,
  '400': #898c9f,
  '500': #6c6f84,
  '600': #57596b,
  '700': #40424f,
  '800': #2c2d35,
  '900': #151519,
);

$_purple: (
  '50': #f2effb,
  '100': #e1daf6,
  '200': #c7b9ee,
  '300': #a995e5,
  '400': #8b70dc,
  '500': #6f4dd3,
  '600': #502db8,
  '700': #3d228b,
  '800': #29175e,
  '900': #140b2d,
);

$_red: (
  '50': #feecf0,
  '100': #fdd8e1,
  '200': #fbb1c3,
  '300': #f986a1,
  '400': #f75f82,
  '500': #f53864,
  '600': #e40c3e,
  '700': #bc061b,
  '800': #750620,
  '900': #3a0310,
);

$_white: (
  '50': #ffffff0d,
  '100': #ffffff1a,
  '200': #ffffff33,
  '300': #ffffff4d,
  '400': #ffffff66,
  '500': #ffffff80,
  '600': #ffffff99,
  '700': #ffffffcc,
  '800': #ffffffe5,
  '900': #ffffff,
);

$_yellow: (
  '50': #fffcf0,
  '100': #fff7db,
  '200': #fff1bd,
  '300': #ffe999,
  '400': #ffe175,
  '500': #ffd953,
  '600': #ffcb0f,
  '700': #cca000,
  '800': #8a6c00,
  '900': #423400,
);

$_orange: (
'50': #ffeeeb,
'100': #ffe1db,
'200': #ffc2b8,
'300': #ffa08f,
'400': #ff816b,
'500': #ff6348,
'600': #ff2b05,
'700': #c21d00,
'800': #851400,
'900': #420a00,
);

$color-background-blue: #004a61e5;
$color-background-header: map.get($_blue, '500');
$color-background-accent-blue: map.get($_blue, '50');
$color-background-accent-green: map.get($_green, '50');
$color-background-accent-grey: map.get($_grayscale, '50');
$color-background-accent-orange: map.get($_orange, '50');
$color-background-accent-purple: map.get($_purple, '50');
$color-background-accent-red: map.get($_red, '50');
$color-background-accent-yellow: map.get($_yellow, '50');
$color-background-button-destructive-active: map.get($_red, '800');
$color-background-button-destructive-disable: map.get($_red, '200');
$color-background-button-destructive-hover: map.get($_red, '700');
$color-background-button-destructive-normal: map.get($_red, '600');
$color-background-button-primary-active: map.get($_blue, '700');
$color-background-button-primary-disable: map.get($_grayscale, '50');
$color-background-button-primary-hover: map.get($_blue, '600');
$color-background-button-primary-normal: map.get($_blue, '500');
$color-background-button-secondary-active: map.get($_white, '900');
$color-background-button-secondary-disable: map.get($_white, '900');
$color-background-button-secondary-hover: map.get($_grayscale, '50');
$color-background-button-secondary-normal: map.get($_white, '900');
$color-background-card-alert: map.get($_red, '600');
$color-background-card-blue: map.get($_blue, '600');
$color-background-card-disable: map.get($_grayscale, '100');
$color-background-card-green: map.get($_green, '600');
$color-background-card-white: map.get($_white, '900');
$color-background-card-yellow: map.get($_yellow, '600');
$color-background-checkbox-checked: map-get($_blue, '500');
$color-background-checkbox-disabled: map-get($_grayscale, '50');
$color-background-checkbox-hover: map-get($_blue, '600');
$color-background-checkbox-normal: map-get($_white, '900');
$color-background-chip-blue: map-get($_blue, '500');
$color-background-chip-green: map-get($_green, '500');
$color-background-chip-orange: map-get($_orange, '500');
$color-background-chip-purple: map-get($_purple, '500');
$color-background-chip-red: map-get($_red, '500');
$color-background-chip-yellow: map-get($_yellow, '500');
$color-background-input-disable: map-get($_grayscale, '50');
$color-background-input-hover: map-get($_grayscale, '50');
$color-background-input-normal: map-get($_white, '900');
$color-background-tooltip-normal: map-get($_blue, '600');
$color-background-modal: map.get($_grayscale, '600');
$color-border-accent-blue: map.get($_blue, '500');
$color-border-accent-gray: map.get($_grayscale, '300');
$color-border-accent-green: map.get($_green, '300');
$color-border-accent-orange: map.get($_orange, '300');
$color-border-accent-purple: map.get($_purple, '300');
$color-border-accent-red: map.get($_red, '300');
$color-border-accent-yellow: map.get($_yellow, '700');
$color-border-button-default: map.get($_grayscale, '200');
$color-border-button-disable: map.get($_grayscale, '100');
$color-border-button-hover: map.get($_grayscale, '300');
$color-border-button-select: map.get($_grayscale, '400');
$color-border-checkbox-disable: map.get($_grayscale, '200');
$color-border-checkbox-hover: map.get($_grayscale, '300');
$color-border-checkbox-primary: map.get($_grayscale, '200');
$color-border-divider-normal: map.get($_grayscale, '100');
$color-border-input-active: map.get($_blue, '600');
$color-border-input-disable: map.get($_grayscale, '200');
$color-border-input-error: map.get($_red, '500');
$color-border-input-hover: map.get($_grayscale, '300');
$color-border-input-normal: map.get($_grayscale, '200');
$color-brand-promsy-black: map.get($_grayscale, '900');
$color-brand-promsy-blue: map.get($_blue, '500');
$color-brand-promsy-white: map.get($_white, '900');
$color-icon-primary-disabled: map.get($_grayscale, '200');
$color-icon-primary-hover: map.get($_grayscale, '600');
$color-icon-primary-normal: map.get($_grayscale, '400');
$color-icon-accent-blue: map.get($_blue, '500');
$color-icon-accent-blue-disabled: map.get($_grayscale, '200');
$color-icon-accent-blue-hover: map.get($_blue, '600');
$color-icon-accent-green: map.get($_green, '700');
$color-icon-accent-grey: map.get($_grayscale, '700');
$color-icon-accent-orange: map.get($_orange, '700');
$color-icon-accent-purple: map.get($_purple, '700');
$color-icon-accent-red: map.get($_red, '700');
$color-icon-accent-yellow: map.get($_yellow, '700');
$color-icon-button-primary-disabled: map.get($_grayscale, '200');
$color-icon-button-primary-hover: map.get($_white, '900');
$color-icon-button-primary-normal: map.get($_white, '900');
$color-icon-button-secondary-disabled: map.get($_grayscale, '200');
$color-icon-button-secondary-hover: map.get($_grayscale, '400');
$color-icon-button-secondary-normal: map.get($_grayscale, '400');
$color-icon-checkbox-checked: map.get($_blue, '500');
$color-icon-checkbox-checked-disabled: map.get($_grayscale, '200');
$color-icon-fill-black: map.get($_grayscale, '900');
$color-icon-fill-blue: map.get($_blue, '300');
$color-icon-fill-green: map.get($_green, '600');
$color-icon-fill-grey: map.get($_grayscale, '500');
$color-icon-fill-orange: map.get($_orange, '500');
$color-icon-fill-promsy: map.get($_blue, '500');
$color-icon-fill-purple: map.get($_purple, '500');
$color-icon-fill-red: map.get($_red, '500');
$color-icon-fill-white: map.get($_white, '900');
$color-icon-fill-yellow: map.get($_yellow, '500');
$color-icon-stat-negative: map.get($_red, '500');
$color-icon-stat-positive: map.get($_green, '600');
$color-icon-stat-warning: map.get($_yellow, '800');
$color-progress-bar-blue: map.get($_blue, '300');
$color-progress-bar-disabled: map.get($_grayscale, '500');
$color-progress-bar-green: map.get($_green, '500');
$color-progress-bar-promsy: map.get($_blue, '500');
$color-progress-bar-purple: map.get($_purple, '500');
$color-progress-bar-red: map.get($_red, '500');
$color-progress-bar-tracker: map.get($_grayscale, '100');
$color-progress-bar-yellow: map.get($_yellow, '500');
$color-text-default: map.get($_grayscale, '300');
$color-text-disable: map.get($_grayscale, '200');
$color-text-error: map.get($_red, '600');
$color-text-link: map.get($_blue, '500');
$color-text-link-hover: map.get($_blue, '700');
$color-text-primary: map.get($_grayscale, '900');
$color-text-secondary: map.get($_white, '900');
$color-text-secondary-disable: map.get($_grayscale, '200');
$color-text-tertiary: map.get($_blue, '500');
$color-text-accent-blue: map.get($_blue, '700');
$color-text-accent-green: map.get($_green, '700');
$color-text-accent-grey: map.get($_grayscale, '700');
$color-text-accent-orange: map.get($_orange, '700');
$color-text-accent-purple: map.get($_purple, '700');
$color-text-accent-red: map.get($_red, '700');
$color-text-accent-yellow: map.get($_yellow, '800');
$color-text-button-link: map.get($_blue, '500');
$color-text-button-link-hover: map.get($_blue, '700');
$color-text-button-primary-disabled: map.get($_grayscale, '200');
$color-text-button-primary-normal: map.get($_grayscale, '900');
$color-text-button-secondary-disable: map.get($_grayscale, '200');
$color-text-button-secondary-normal: map.get($_white, '900');
$color-text-stat-negative: map.get($_red, '500');
$color-text-stat-positive: map.get($_green, '600');
$color-text-stat-warning: map.get($_yellow, '800');
$surface-black: map.get($_grayscale, '900');
$surface-default: map.get($_grayscale, '50');
$surface-white: map.get($_white, '900');
