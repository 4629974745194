@use 'sass:map' as map;
$_radius: (
        'none': 0,
        'r-4': 4px,
        'r-8': 8px,
        'r-12': 12px,
        'r-16': 16px,
        'r-32': 32px,
        'r-40': 40px,
        'r-64': 64px,
        'r-full':  360px
);
$none: map.get($_radius, 'none');
$radius-r-4: map.get($_radius, 'r-4');
$radius-r-8: map.get($_radius, 'r-8');
$radius-r-12: map.get($_radius, 'r-12');
$radius-r-16: map.get($_radius, 'r-16');
$radius-r-32: map.get($_radius, 'r-32');
$radius-r-40: map.get($_radius, 'r-40');
$radius-r-64: map.get($_radius, 'r-64');
$radius-r-full: map.get($_radius, 'r-full');
