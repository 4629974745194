/// Breakpoints names
$small: 'small';
$standard: 'standard';


/// Breakpoints
$breakpoints-pqf: ($small: 0px, $standard: 1920px);

@mixin height-width-100 {
  height: 100%;
  width: 100%;
}

@mixin _flex {
  display: flex;
}

@mixin _flex-row {
  @include _flex;
  flex-direction: row;
}

@mixin flex-row-wrap {
  @include _flex-row;
  flex-wrap: wrap;
}

@mixin flex-row {
  @include _flex-row;
}

@mixin flex-column {
  @include _flex;
  flex-direction: column;
}

@mixin flex-center-items {
  align-items: center;
  justify-content: center;
}

// MediaQuery
$small-screen: 1366px;
$medium-screen: 1920px;
$large-screen: 2560px;

@mixin custom-media-query($size) {
  @media (min-width: $size) {
    @content;
  }
}
