@use "displayed" as s;
@use "colors" as c;
@use "sass:map";

$browser-context: 16;

$font-family-inter: 'Inter', sans-serif;

$font_weight_thin: 100;
$font_weight_light: 300;
$font_weight_regular: 400;
$font_weight_medium: 500;
$font_weight_semi_bold: 600;
$font_weight_bold: 700;
$font_weight_black: 900;

//DOCS: Fonts sizes are in rem
$font-size-12: (
  'font-size': 0.75,
  'line-height': 1.125
);

$font-size-12-alt: (
  'font-size': 0.75,
  'line-height': 1
);
$font-size-14: (
  'font-size': .875,
  'line-height': 1.25
);

$font-size-16: (
  'font-size': 1,
  'line-height': 1.5
);

$font-size-20: (
  'font-size': 1.25,
  'line-height': 1.5
);
$font-size-24: (
  'font-size': 1.5,
  'line-height': 1.75
);
$font-size-32: (
  'font-size': 2,
  'line-height': 2.5
);

$font-size-40: (
  'font-size': 2.5,
  'line-height': 3
);

$font-size-48: (
  'font-size': 3,
  'line-height': 3.5
);

$font-size-60: (
  'font-size': 3.75,
  'line-height': 4.5
);


@mixin responsive-font-format(
  $font-weight: $font-weight-regular,
  $font-size: $font-size-12,
  $color: c.$color-brand-promsy-black) {
  &, & * {
    @include _responsive-font-size($font-size);
    color: $color;
    font-weight: $font-weight;
  }
}

/// Apply font-size property with media queries
///
/// @param {map} $font-size-map
///   A map with font-size property for each breakpoint.
/// @return {number} `The measure in rem`.
@mixin _responsive-font-size($font-size-map) {
  @each $property, $value in $font-size-map {
    #{$property}: $value * 1rem;
  }
}

@mixin media-query($breakpoint-name) {
  @if map-has-key(s.$breakpoints-pqf, $breakpoint-name) {
    @media (min-width: map.get(s.$breakpoints-pqf, $breakpoint-name)) {
      @content;
    }
  } @else {
    @warn "Size doesn't exist `#{$breakpoint-name}`. "+"in breakpoints list, check map `$breakpoints`.";
  }
}

@mixin multiLineTextTruncateSinHeight(
  $lines: 2,
  //DOCS: Uncomment if you need it
  //$lineHeight: 1.5em,
  //$bgcolor: rgba(255, 255, 255, 1),
  //$transColor: rgba(25, 255, 255, 0)
) {
  @supports (-webkit-line-clamp: $lines) {
    display: block;
    display: -webkit-box !important;
    //DOCS: Uncomment if you need it
    //line-height: $lineHeight;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  @supports not (-webkit-line-clamp: $lines) {
    position: relative;
    //DOCS: Uncomment if you need it
    //line-height: $lineHeight;
    overflow: hidden;
    width: 100%;
    word-break: break-all;
    &:before {
      content: '\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0';
      position: absolute;
      bottom: 0;
      right: 0;
      //DOCS: Uncomment if you need it
      //background: transparent linear-gradient(to right, $transColor, $bgcolor 50%) repeat scroll 0% 0%;
    }
  }
}
