@use 'colors' as c;
@use 'displayed' as d;
@use 'font' as f;
@use 'radius' as r;
@use 'shadows' as s;
@import 'normalize';

html,
body {
  @include d.height-width-100;
  font-size: (f.$browser-context * 1px);
  line-height: (f.$browser-context * 1.5px);
  font-family: f.$font-family-inter;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}
.mat-dialog-style {
  & > mat-dialog-container {
    border-radius: r.$radius-r-8;
    border: 1px solid c.$color-icon-accent-blue;
    & > .mdc-dialog__container {
      & > .mat-mdc-dialog-surface {
        border-radius: r.$radius-r-8;
        box-shadow: s.$overlay !important;
        overflow: visible;
      }
    }
  }
}

.mat-dialog-background {
  background: c.$color-background-modal;
  opacity: 80% !important;
}

.backdrop-dialog {
  background: rgba(c.$color-icon-primary-hover, 0.8);
}

.size-terms-and-conditions {
  height: 674px;
  width: 651px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: c.$surface-default; /* Color de fondo del track */
}

::-webkit-scrollbar-thumb {
  background: c.$color-text-secondary-disable;
  border-radius: r.$radius-r-8;
}

::-webkit-scrollbar-thumb:hover {
  background: c.$color-text-secondary-disable;
}

.mat-mdc-dialog-container {
  //DOCS: Delete styles of matdialog
  --mdc-dialog-container-elevation-shadow: none !important;
  --mdc-dialog-container-elevation: var(--mdc-dialog-container-elevation-shadow);
  --mdc-dialog-container-shadow-color: none !important;
  --mdc-dialog-container-shape: r.$none !important;
  outline: 0;
}

.mdc-dialog__surface {
  //DOCS: Delete styles of matdialog
  background-color: transparent !important;
  border-radius: inherit;
  box-shadow: none !important;
}
